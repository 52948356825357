import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  
  { // 首页
    path: '/',
    mode:'history',
    base:'/vue/',
    component: () => import('@/views/index.vue'),
    children: [
      {
        path: '/',
        name: 'index',
        component: () => import('../views/homePage/indexPage.vue')
      }, 
      { // Company Profile
        path: '/CompanyProfile',
        name: 'CompanyProfile',
        component: () => import('@/views/menu/CompanyProfile.vue')
      },
      {
        // Our Services
        path: '/OurServices',
        name: 'OurServices',
        component: () => import('@/views/menu/OurServices.vue')
      },
      {
        // Contact
        path: '/ContactUs',
        name: 'ContactUs',
        component: () => import('@/views/menu/ContactUs.vue')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
