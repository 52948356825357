<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  data() {
    return {
      swiperInstance: null,
      isMobile: false,
    };
  }
}
</script>
<style>
*{margin:0;padding:0;}
body {
  font-family: Arial, sans-serif;
}
ul li {
  list-style-type: none;
}
.box{
    width: 100%;
    height:calc(100vh - 80px);
}
.div2{width:100%;height:100%;}
.div2>img{
  width: 100%;
  height: 100%;
}
.swiper-container {
  height: 100%;
  width: 100%;
}
.swiper-wrapper .swiper-slide {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 100%;
}
.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet{margin:10px 0!important;}
.swiper-pagination-bullet-active{background:#ffffff!important;}
.footer{
  height:100px;
    width: 100%;
    background-color: rgb(210 215 134);
    background-image: url(./assets/images/logo.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 70px;}
</style>
